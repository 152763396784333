<template>
  <div class="scale-samples flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <date-range
          v-model="filters"
          @input="fetchSamples()"
        />

        <div class="flex flex-column gap-1">
          <div>Scale:</div>
          <button-select
            v-model="filters.device_id"
            :options="options.device_id"
            size="sm"
            @input="fetchSamples()"
          />
        </div>
      </div>

      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createSample()"
        >
          Add new sample
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ samples.length | format }} / {{ sampleCount | format }} sample(s)
    </div>

    <table class="table table-google table-weights">
      <thead>
        <tr>
          <th>ID</th>
          <th>Scale</th>
          <th class="text-nowrap">Sample Number</th>
          <th class="text-nowrap">Sample Date</th>
          <th class="text-nowrap">Cycle 1</th>
          <th class="text-nowrap">Cycle 2</th>
          <th class="text-nowrap">Cycle 3</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="8">
            <div class="font-bold">
              <span v-if="isLoading && samples.length === 0">Loading..</span>
              <span v-else-if="samples.length === 0">No samples found</span>
            </div>
          </td>
        </tr>

        <tr v-for="sample in samples" :key="sample.id">
          <td class="row-fit text-nowrap">{{ sample.id | format }}</td>
          <td class="row-fit text-nowrap">{{ sample.device_description }}</td>
          <td class="text-nowrap">{{ sample.sample_number }}</td>
          <td class="row-fit text-nowrap">{{ sample.sample_date | isodate }}</td>
          <td class="row-fit">
            <sample-cycle
              :sample="sample"
              :cycles="sample.cycles"
              :cycleNumber="1"
              @startBurning="startBurning(sample)"
              @stopBurning="stopBurning(sample)"
            />
          </td>
          <td class="row-fit">
            <sample-cycle
              :sample="sample"
              :cycles="sample.cycles"
              :cycleNumber="2"
              @startBurning="startBurning(sample)"
              @stopBurning="stopBurning(sample)"
            />
          </td>
          <td class="row-fit">
            <sample-cycle
              :sample="sample"
              :cycles="sample.cycles"
              :cycleNumber="3"
              @startBurning="startBurning(sample)"
              @stopBurning="stopBurning(sample)"
            />
          </td>
          <td class="row-fit text-nowrap">
            <div class="flex gap-2">
              <b-btn
                variant="primary"
                size="sm"
                @click="editSample(sample)"
              >
                Show
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="sampleCount"
      :per-page="filters.per_page"
      @input="fetchSamples(true)"
      :disabled="isLoading"
    ></b-pagination>

    <device-selector
      v-if="deviceSelector.showModal"
      :data="deviceSelector"
      @select="selectDevice"
    />

    <sample-modal
      v-if="sampleModal.showModal"
      :data="sampleModal"
      @change="fetchSamples()"
      @updated="onUpdated"
      @deleted="onDeleted"
    />
  </div>
</template>

<script>
import { formatDuration } from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const DateRange = () => import('@/components/invoices/DateRange.vue');
const DeviceSelector = () => import('@/components/scale/DeviceSelector.vue');
const SampleCycle = () => import('@/components/soot_samples/SampleCycle.vue');
const SampleModal = () => import('@/components/soot_samples/SampleModal.vue');

export default {
  name: 'ScaleSamples',
  components: {
    ButtonSelect,
    DateRange,
    DeviceSelector,
    SampleCycle,
    SampleModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      samples: [],
      sampleCount: 0,
      devices: [],
      filters: {
        date_from: null,
        date_to: null,
        device_id: null,
        page: 1,
        per_page: 500,
      },
      options: {
        device_id: [],
      },
      deviceSelector: {
        showModal: false,
        data: null,
      },
      sampleModal: {
        showModal: false,
        data: null,
      },
      timer: null,
    };
  },
  methods: {
    updateDuration() {
      const now = parseInt(new Date().getTime() / 1000, 10);
      this.samples.forEach((sample) => {
        sample.cycles.forEach((cycle) => {
          const startTime = cycle.weight_start_ts ?? now;
          const endTime = cycle.weight_end_ts ?? now;
          cycle.burn_duration = endTime - startTime;
        });
      });
    },
    formatDuration(seconds) {
      return formatDuration(seconds);
    },
    fetchDevices() {
      this.loadingCount++;
      this.$http
        .get('/scale/devices')
        .then((res) => {
          const deviceOptions = [
            { value: null, text: 'All' },
          ];
          res.body.devices.forEach((device) => {
            deviceOptions.push({ value: device.id, text: device.device_description });
          });
          this.options.device_id = deviceOptions;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch devices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSampleData() {
      this.loadingCount++;
      this.$http
        .get('/soot_sample/samples')
        .query(this.filters)
        .then((res) => {
          this.samples = res.body.samples.map((sample) => {
            sample.cycles.forEach((cycle) => {
              cycle.burn_duration = null;
            });
            sample.busy_progress = false;
            return sample;
          });
          this.updateDuration();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch samples: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSampleCount() {
      this.loadingCount++;
      this.$http
        .get('/soot_sample/samples_count')
        .query(this.filters)
        .then((res) => {
          this.sampleCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSamples(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchSampleCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchSampleData();
    },
    createSample() {
      this.deviceSelector.showModal = true;
    },
    editSample(sample) {
      this.sampleModal.sampleId = sample.id;
      this.sampleModal.showModal = true;
    },
    markProgress(sample, progressType) {
      this.loadingCount++;
      sample.busy_progress = true;
      this.$http
        .post('/soot_sample/markProgress')
        .send({ sampleId: sample.id })
        .send({ progressType })
        .then((res) => {
          Object.keys(res.body.sample).forEach((key) => {
            sample[key] = res.body.sample[key];
          });
          this.updateDuration();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          sample.busy_progress = false;
        });
    },
    startBurning(sample) {
      this.markProgress(sample, 'start');
    },
    stopBurning(sample) {
      if (!confirm('Do you really wish to end the cycle?')) {
        return;
      }
      this.markProgress(sample, 'end');
    },
    selectDevice(device) {
      this.loadingCount++;
      this.$http
        .post('/soot_sample/samples')
        .send({ device_id: device.id })
        .then((res) => {
          const { sample } = res.body;
          sample.busy_progress = false;
          this.samples.unshift(sample);
          this.sampleCount++;
          this.editSample(sample);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onUpdated(sample) {
      const localSample = this.samples.find((item) => item.id === sample.id);
      if (localSample) {
        Object.keys(sample).forEach((key) => {
          localSample[key] = sample[key];
        });
      }
    },
    onDeleted(sample) {
      this.samples = this.samples.filter((item) => item.id !== sample.id);
      this.sampleCount--;
    },
  },
  created() {
    this.fetchDevices();
    this.fetchSamples();
    this.timer = setInterval(() => {
      this.updateDuration();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
  .scale-samples {
    max-width: 1000px;
  }
</style>
